<template>
  <div>
    <trac-tab-window
      :sectionTitle="'Inventory'"
      :subLinks="subLinksOptions"
    />
  </div>
</template>

<script>
import MixinForScrollToTop from '../../offline-module/mixins/TabbedMixin'

export default {
  data() {
    return {
      subLinksOptions: [
        {
          name: "Stock Adjustment",
          path: "/inventory/stock-adjustment",
          selected: false,
          routerWindowClassString: 'bg-white mt-12 p-12 big-shadow',
          dataAttribute: "",
        },
        {
          name: "Stock Count",
          path: "/inventory/stock-count",
          selected: false,
          routerWindowClassString: 'bg-white mt-12 p-12 big-shadow',
          dataAttribute: "",
        },
        {
          name: "Inventory Transfer",
          path: "/inventory/inventory-transfer",
          selected: false,
          routerWindowClassString: 'bg-white mt-12 p-12 big-shadow',
          dataAttribute: "",
        },
        {
          name: "Stock Label",
          path: "/inventory/print-label",
          selected: false,
          routerWindowClassString: 'bg-white mt-12 p-12 big-shadow',
          dataAttribute: "",
        },
      ],
    };
  },
  mixins: [MixinForScrollToTop],
};
</script>

<style lang="scss" scoped>
</style>